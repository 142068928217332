import React from "react";
import styled from "styled-components";
import { AppText, AppTitle2, FlexDiv } from "../../ui";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import StarsSrc from "../../../content/assets/stars-gold.svg";
import { AppLink } from "../../ui/AppLink";
import { Awards } from "../index/Awards";
import { Carousel } from "react-responsive-carousel";
import { OfferSegmentI } from "../../../hooks/useOffer";
import { offer_landing_page_click } from "../../../services/mixpanel/discount-flow-events";

export const TestimoniesSegment: React.FC<OfferSegmentI> = ({ priceData }) => {
  const { percentOff, coupon_id, price_id, trial } = priceData;
  return (
    <Background>
      <Container>
        <AppTitle2 margin="0" textAlign="center">
          Join millions of people learning on Imprint
        </AppTitle2>
        <Stars src={StarsSrc} alt="5 stars" width={232} height={43} />
        <CarouselWrapper>
          <StyledCarousel
            infiniteLoop
            showArrows
            centerMode
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
          >
            <FlexDiv align="center" justify="center">
              <AppText noMargin fontSize={28} textAlign="center" style={{ maxWidth: 700 }}>
                “Imprint is <b>completely life changing</b>. As a person that always loves reading
                but doesn’t have much time, this app is <b>perfect for me</b>.”
              </AppText>
            </FlexDiv>
            <FlexDiv align="center" justify="center">
              <AppText noMargin fontSize={28} textAlign="center" style={{ maxWidth: 700 }}>
                “This app is <b>one of the best things</b> you can do for yourself.”
              </AppText>
            </FlexDiv>
            <FlexDiv align="center" justify="center">
              <AppText noMargin fontSize={28} textAlign="center" style={{ maxWidth: 700 }}>
                “I&apos;m <b>learning</b> during my lunch breaks <b>instead of scrolling</b> through
                Instagram and I <b>use my new knowledge</b> during my work day.”
              </AppText>
            </FlexDiv>
          </StyledCarousel>
        </CarouselWrapper>
        <StyledLink
          to="/subscription/register?from=email"
          state={{
            couponId: coupon_id,
            priceId: price_id,
            trial,
          }}
          onClick={() => offer_landing_page_click("testimony")}
        >
          {percentOff ? `Get ${percentOff}% Off Imprint` : "Start Imprint Free Trial"}
        </StyledLink>
        <Awards dark />
      </Container>
    </Background>
  );
};

const Background = styled.section`
  background-color: ${theme.WHITE_COLOR};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    padding: 64px 24px 36px 24px;
  }
`;

const Stars = styled.img`
  @media ${mobileSize} {
    width: 129px;
    height: 24px;
  }
`;

const StyledLink = styled(AppLink as any)`
  margin-bottom: 40px;
  @media ${mobileSize} {
    margin-bottom: 0px;
  }
`;

const CarouselWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1012px;
  width: 100%;
  .carousel-root {
    width: 100%;
  }
  @media ${mobileSize} {
    width: 75%;
  }
`;

const StyledCarousel = styled(Carousel)`
  .carousel {
    background-color: ${theme.WHITE_COLOR};
    margin: 0 auto;
    border-radius: 16px;
    padding: 64px 0;
    @media ${mobileSize} {
      padding: 0;
    }
  }
  .carousel.carousel-slider .control-arrow,
  .carousel .control-arrow {
    max-height: 90%;
    background-color: rgba(0, 0, 0, 0);
    /* opacity: 0.; */
  }

  .carousel .control-next.control-arrow::before,
  .carousel .control-prev.control-arrow::before {
    content: "";
    position: absolute;
    border: solid #8f9295;
    border-width: 0 6px 6px 0;
    display: inline-block;

    padding: 12px;
    box-shadow: none;
    background: none;

    @media ${mobileSize} {
      padding: 6px;
      border-width: 0 3px 3px 0;
    }
  }

  .carousel .control-next.control-arrow::before {
    right: -60px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);

    @media ${mobileSize} {
      right: -40px;
    }
  }

  .carousel .control-prev.control-arrow::before {
    left: -60px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);

    @media ${mobileSize} {
      left: -40px;
    }
  }

  .carousel.carousel-slider {
    overflow: inherit;
  }
  /* .carousel .slider-wrapper.axis-horizontal .slider .slide {
    min-width: 100%;
  } */
  li {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }
  ul .selected {
    opacity: 1;
  }
  div {
    height: 100%;
  }
`;
